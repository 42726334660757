<template>
  <v-app>
    <Menu :rol="1" />
    <v-main class="appBackground">
      <Banner></Banner>
      <v-scroll-x-transition mode="out-in" hide-on-leave>
        <router-view />
      </v-scroll-x-transition>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

import Menu from "./components/Menu.vue";
import Banner from "./components/Banner.vue";

export default {
  name: "App",
  components: {
    Menu,
    Banner,
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  created() {
    var a = Vue.$cookies.get("a");
    if (a != null) {
      this.$store.commit("setLogin", a);
    }

    if (this.accessToken === null) {
      this.$router.push("login");
    } else {
      this.$store.dispatch("loadProfile").then(response => {
        this.$router.push(response);
      });
    }
  },
};
</script>

<style>
body {
  margin: 0px;
  height: 100%;
}

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

h3 {
  margin: 5px;
}
</style>
