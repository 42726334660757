<template>
  <v-snackbar
    v-model="show"
    :multi-line="multiLine"
    timeout="3000"
    :color="bannerType"
    right
    bottom
    transition="v-snack-transition"
  >
    {{ bannerMessage }}
    <!--<template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="show = false">
        Cerrar
      </v-btn>
    </template>-->
  </v-snackbar>
</template>

<script>
export default {
  name: "Banner",
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showBanner") {
        this.bannerMessage = state.bannerMessage;
        this.bannerType = state.bannerType;
        this.show = true;
      }
    });
  },
  data: () => ({
    bannerMessage: "",
    bannerType: "success",
    show: false,
    multiLine: true,
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
