/* eslint-disable prettier/prettier */
/*eslint no-warning-comments: "error"*/

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
    state: {
        bannerMessage: "",
        bannerType: "Success",
        customDialog: {
            title: "",
            body: "",
            visible: false,
        },
        loading: false,
        accessToken: null,
        accessMode: null,
        operator: null,
        transportcodes: [],
        operatorApi: null,
        integrator: null,
        users: [],
        relationTypes: null,
        clients: null,
        webMethods: null,
        profiles: null,
        colectives: null,
    },
    getters: {
        getAccessToken: state => {
            return state.accessToken;
        },
        getBasicAuth: state => {
            return {
                headers: {
                    Authorization: state.accessToken,
                },
            };
        },
        getBannerMessage: state => {
            return state.bannerMessage;
        },
        getBannerType: state => {
            return state.bannerType;
        },
        getCustomDialog: state => {
            return state.customDialog;
        },
        getLoading: state => {
            return state.loading;
        },
        getAccessMode: state => {
            return state.accessMode;
        },
        getOperator: state => {
            return state.operator;
        },
        getMediaAccess: state => {
            if (state.operator) return state.operator.mediaAccess;
            else return [];
        },
        getIntegrators: state => {
            return state.operator.integrators;
        },
        getTransportcodes: state => {
            return state.transportcodes;
        },
        getOperatorApi: state => {
            return state.operatorApi;
        },
        getIntegrator: state => {
            return state.integrator;
        },
        getUsers: state => {
            return state.users;
        },
        getClients: state => {
            return state.clients;
        },
        getRelationTypes: state => {
            return state.relationTypes;
        },
        getWebMethods: state => {
            return state.webMethods;
        },
        getProfiles: state => {
            return state.profiles;
        },
        getColectives: state => {
            return state.colectives;
        },
    },

    mutations: {
        showBanner: (state, payload) => {
            state.bannerMessage = payload.bannerMessage;
            state.bannerType = payload.bannerType;
        },
        showCustomDialog: (state, dialog) => {
            state.customDialog = { visible: true, title: dialog.title, body: dialog.body };
        },
        setLoading: (state, payload) => {
            state.loading = payload;
        },
        setAccessMode: (state, payload) => {
            state.accessMode = payload;
        },
        setLogin: (state, payload) => {
            Vue.$cookies.set("a", payload);
            state.accessToken = payload;
        },
        setLogout: state => {
            Vue.$cookies.remove("a");
            state.accessToken = null;
            state.accessMode = null;
            state.aoperator = null;
            state.integrator = null;
        },
        setOperator: (state, payload) => {
            state.operator = payload;
        },
        setMediaAccess: (state, payload) => {
            state.operator.mediaAccess = payload;
        },
        appendMediaAccess: (state, payload) => {
            state.operator.mediaAccess.push(payload);
        },
        setIntegrators: (state, payload) => {
            state.operator.integrators = payload;
        },
        appendIntegrator: (state, payload) => {
            state.operator.integrators.push(payload);
        },
        setTransportcodes: (state, payload) => {
            state.transportcodes = payload;
        },
        appendTransportcode: (state, payload) => {
            state.transportcodes.push(payload);
        },
        setOperatorApi: (state, payload) => {
            state.operatorApi = payload;
        },
        setIntegrator: (state, payload) => {
            state.integrator = payload;
        },
        setUsers: (state, payload) => {
            state.users = payload;
        },
        setClients: (state, payload) => {
            state.clients = payload;
        },
        setRelationTypes: (state, payload) => {
            state.relationTypes = payload;
        },
        setWebMethods: (state, payload) => {
            state.webMethods = payload;
        },
        setProfiles: (state, payload) => {
            state.profiles = payload;
        },
        setColectives: (state, payload) => {
            state.colectives = payload;
        },
    },

    actions: {
        logout: context => {
            context.commit("setLogout", null);
        },

        /* Login contra OPENAPI. Si todo va bien obtiene los datos del operador */
        login: (context, payload) => {
            context.commit("setLoading", true);

            return new Promise((resolve, reject) => {
                let credentials = {
                    headers: {
                        email: payload.email,
                        password: payload.password,
                    },
                };
                // Login to OpenAPI
                axios
                    .get(process.env.VUE_APP_OPENAPI_URL + "/v2/mobilitylabs/user/login/", credentials)
                    .then(response => {
                        context.commit("setLogin", response.data.data[0].accessToken);
                        context.dispatch("loadProfile").then(
                            response => {
                                resolve(response);
                            },
                            response => {
                                reject(response);
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error.message);
                        context.commit("setLoading", false);
                        reject("Email o contraseña no válidos");
                    });
            });
        },

        /* Carga el perfil según modo de acceso operador o integrador */
        loadProfile: context => {
            return new Promise((resolve, reject) => {
                context.dispatch("fetchOperator").then(
                    () => {
                        context.commit("setAccessMode", "operator");
                        resolve("/operator");
                        context.commit("setLoading", false);
                    },
                    () => {
                        context.dispatch("fetchIntegrator").then(
                            () => {
                                context.commit("setAccessMode", "integrator");
                                resolve("/integrator");
                                context.commit("setLoading", false);
                            },
                            () => {
                                context.commit("setLogout");
                                reject("Acceso no permitido");
                                context.commit("setLoading", false);
                            }
                        );
                    }
                );
            });
        },

        /* Obtener el operador a partir del accessToken */
        fetchOperator: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/operators", context.getters.getBasicAuth)
                    .then(response => {
                        if (response.data.code == 0) {
                            response.data.data.aesParaph = atob(
                                response.data.data.aesParaph.replace("<", "").replace(">", "")
                            );
                            context.commit("setOperator", response.data.data);
                            context.dispatch("fetchOperatorApi");
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Obtiene una clave AES válida */
        generateAES: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .get(
                        process.env.VUE_APP_API_URL + "/api/1/federation/aesParaph",
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena un operador */
        saveOperator: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/operators",
                        context.getters.getOperator,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            resolve(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Operador almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena un medio de acceso de un operador */
        saveMediaAccess: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/mediaAccess",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Medio de acceso almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Obtiene los clientes de Emting */
        fetchClients: context => {
            axios
                .get(
                    process.env.VUE_APP_API_URL + "/api/1/federation/Clients",
                    context.getters.getBasicAuth
                )
                .then(response => {
                    context.commit("setClients", response.data.data);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },

        getAvaliableClients: context => {
            return new Promise(resolve => {
                var c = context.getters.getClients;
                var opIntegrators = context.getters.getOperator.integrators;
                var response = [];
                for (let i = 0; i < c.length; i++) {
                    var founded = false;
                    for (let j = 0; j < opIntegrators.length; j++) {
                        if (opIntegrators[j].integratorId == c[i].id) {
                            founded = true;
                            break;
                        }
                    }
                    if (!founded) response.push(c[i]);
                }
                resolve(response);
            });
        },

        /* Almacena una relación entre un operador y un integrador */
        saveOperatorIntegrator: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/OperatorIntegrator",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Integrador almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Obtiene los métodos web */
        fetchWebMethods: context => {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/1/webmethods")
                .then(response => {
                    context.commit("setWebMethods", response.data.data);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },

        /* Obtiene los perfiles */
        fetchProfiles: context => {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/1/federation/profiles")
                .then(response => {
                    context.commit("setProfiles", response.data.data);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },

        /* Obtiene los colectives */
        fetchColectives: context => {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/1/federation/colectives")
                .then(response => {
                    context.commit("setColectives", response.data.data);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },

        /* Obtiene los tipos de relación */
        fetchRelationTypes: context => {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/1/federation/relationships")
                .then(response => {
                    context.commit("setRelationTypes", response.data.data);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },

        getAvaliableWebMethods: context => {
            return new Promise(resolve => {
                var methods = context.getters.getWebMethods;
                var api = context.getters.getOperatorApi;
                var response = [];
                for (let i = 0; i < methods.length; i++) {
                    var founded = false;
                    for (let j = 0; j < api.length; j++) {
                        if (api[j].webMethodId == methods[i].id) {
                            founded = true;
                            break;
                        }
                    }
                    if (!founded) response.push(methods[i]);
                }
                resolve(response);
            });
        },

        /* Obtiene los títulos del operador */
        fetchTransportcodes: context => {
            return new Promise((resolve, reject) => {
                let credentials = {
                    headers: {
                        Authorization: context.getters.getAccessToken,
                        richDescription: true,
                    },
                };

                context.commit("setLoading", true);

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/transportcode", credentials)
                    .then(response => {
                        if (response.data.code == 0) {
                            context.commit("setTransportcodes", response.data.data);
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena un titulo */
        saveTransportcode: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/transportcode",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Título almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena el periodo de un titulo */
        saveTransportcodePeriod: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/transportcodePeriod",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Periodo almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena un medio de acceso de un titulo */
        saveTransportcodeMediaAccess: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/transportcodeMediaAccess",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Periodo almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        deleteTransportcode: (context, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(process.env.VUE_APP_API_URL + "/api/1/transportcode", {
                        headers: {
                            Authorization: context.getters.getAccessToken,
                        },
                        data: payload,
                    })
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(false);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Título eliminado correctamente.",
                                bannerType: "success",
                            });
                            resolve(true);
                        }
                    });
            });
        },

        deleteTransportcodePeriod: (context, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(process.env.VUE_APP_API_URL + "/api/1/transportcodePeriod", {
                        headers: {
                            Authorization: context.getters.getAccessToken,
                        },
                        data: payload,
                    })
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(false);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Periodo eliminado correctamente.",
                                bannerType: "success",
                            });
                            resolve(true);
                        }
                    });
            });
        },

        deleteTransportcodeMediaAccess: (context, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(process.env.VUE_APP_API_URL + "/api/1/transportcodeMediaAccess", {
                        headers: {
                            Authorization: context.getters.getAccessToken,
                        },
                        data: payload,
                    })
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(false);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Medio de Acceso eliminado correctamente.",
                                bannerType: "success",
                            });
                            resolve(true);
                        }
                    });
            });
        },

        /* Obtiene los métodos web de la api del operador */
        fetchOperatorApi: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/OperatorApi", context.getters.getBasicAuth)
                    .then(response => {
                        if (response.data.code == 0) {
                            context.commit("setOperatorApi", response.data.data);
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },
        /* Almacena un medio de acceso de un operador */
        saveOperatorApi: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/OperatorApi",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Endpoint almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Obtener el integrador a partir del accessToken */
        fetchIntegrator: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/integrators", context.getters.getBasicAuth)
                    .then(response => {
                        if (response.data.code == 0) {
                            response.data.data.aesParaph = atob(
                                response.data.data.aesParaph.replace("<", "").replace(">", "")
                            );
                            context.commit("setIntegrator", response.data.data);
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Almacena un integrador */
        saveIntegrator: context => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/integrators",
                        context.getters.getIntegrator,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            resolve(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Integrador almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(response);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },
        /* Obtiene los usuarios de un integrador */
        fetchUsers: (context, options) => {
            return new Promise((resolve, reject) => {
                let credentials = {
                    headers: {
                        Authorization: context.getters.getAccessToken,
                        integratorId: context.getters.getIntegrator.integratorId,
                    },
                };

                context.commit("setLoading", true);

                var textSearch = options.search != null ? options.search : "";

                axios
                    .get(
                        process.env.VUE_APP_API_URL +
                        "/api/1/IntegratorUsers?pageSize=" +
                        options.itemsPerPage +
                        "&pageNumber=" +
                        options.page +
                        "&search=" +
                        textSearch,
                        credentials
                    )
                    .then(response => {
                        if (response.data.code == 0) {
                            context.commit("setUsers", response.data.data);
                            resolve(response.data.data);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        console.log(error.message);
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },

        /* Obtiene los medios de acceso de un integrador */
        fetchIntegratorMediaAccess: (context, payload) => {
            return new Promise((resolve, reject) => {
                let credentials = {
                    headers: {
                        Authorization: context.getters.getAccessToken,
                        operatorId: payload.operatorId,
                        integratorId: payload.integratorId,
                    },
                };

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/IntegratorMediaAccess", credentials)
                    .then(response => {
                        if (response.data.code == 0) {
                            resolve(response.data.data);
                        } else {
                            reject(response);
                        }
                    });
            });
        },

        /* Obtiene los medios de acceso de un usuario integrador */
        fetchUserMediaAccess: (context, userId) => {
            return new Promise((resolve, reject) => {
                let credentials = {
                    headers: {
                        Authorization: context.getters.getAccessToken,
                        userId: userId,
                    },
                };

                axios
                    .get(process.env.VUE_APP_API_URL + "/api/1/IntegratorUserMediaAccess", credentials)
                    .then(response => {
                        if (response.data.code == 0) {
                            resolve(response.data.data);
                        } else {
                            reject(response);
                        }
                    });
            });
        },

        /* Almacena un medio de acceso de para un integrador */
        saveIntegratorMediaAccess: (context, payload) => {
            return new Promise((resolve, reject) => {
                context.commit("setLoading", true);

                axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/1/integratorMediaAccess",
                        payload,
                        context.getters.getBasicAuth
                    )
                    .then(response => {
                        if (response.data.code != 0) {
                            context.commit("showBanner", {
                                bannerMessage: response.data.description,
                                bannerType: "error",
                            });
                            reject(response);
                        } else {
                            context.commit("showBanner", {
                                bannerMessage: "Medio de acceso almacenado correctamente.",
                                bannerType: "success",
                            });
                            resolve(payload);
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                    })
                    .finally(() => {
                        context.commit("setLoading", false);
                    });
            });
        },
    },
    modules: {},
});