<template>
  <div>
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list>
        <v-list-item v-show="accessMode == 'operator'" to="/operator">
          Configuración
        </v-list-item>
        <v-list-item v-show="accessMode == 'operator'" to="/integrators">
          Integradores
        </v-list-item>
        <v-list-item v-show="accessMode == 'operator'" to="/transportcodes">
          Títulos
        </v-list-item>
        <v-list-item v-show="accessMode == 'operator'" to="/api">
          API
        </v-list-item>
        <v-list-item v-show="accessMode == 'operator'" to="/keys">
          Claves AES
        </v-list-item>

        <v-list-item v-show="accessMode == 'integrator'" to="/integrator">
          Configuración
        </v-list-item>
        <v-list-item v-show="accessMode == 'integrator'" to="/operators">
          Operadores
        </v-list-item>
        <v-list-item v-show="accessMode == 'integrator'" to="/users">
          Usuarios
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat v-show="accessMode != null" color="appBarColor">
      <v-app-bar-nav-icon color="white" class="hidden-md-and-up" @click="drawer = !drawer" />
      <v-container>
        <v-row align="center">
          <v-img
            :src="require('@/assets/mpass_menu.png')"
            class="ml-2 mr-5"
            contain
            height="40"
            max-width="122"
          />

          <ul class="nav-list d-none d-md-block">
            <li v-show="accessMode == 'operator'">
              <router-link to="/operator">Configuración</router-link>
            </li>
            <li v-show="accessMode == 'operator'">
              <router-link to="/Integrators">Integradores</router-link>
            </li>
            <li v-show="accessMode == 'operator'">
              <router-link to="/transportcodes">Títulos</router-link>
            </li>
            <li v-show="accessMode == 'operator'">
              <router-link to="/API">API</router-link>
            </li>
            <li v-show="accessMode == 'operator'">
              <router-link to="/keys">Claves AES</router-link>
            </li>

            <li v-show="accessMode == 'integrator'">
              <router-link to="/integrator">Configuración</router-link>
            </li>
            <li v-show="accessMode == 'integrator'">
              <router-link to="/operators">Operadores</router-link>
            </li>
            <li v-show="accessMode == 'integrator'">
              <router-link to="/users">Usuarios</router-link>
            </li>
          </ul>
          <ul class="nav-list-secondary">
            <li>
              <a @click="doLogout"><i class="material-icons">power_settings_new</i>Cerrar sesión</a>
            </li>
          </ul>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: () => ({
    drawer: false,
  }),
  computed: {
    accessMode() {
      return this.$store.getters.getAccessMode;
    },
  },
  methods: {
    doLogout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.appBarColor {
  border-bottom: 1px solid #0033a0;
  background-color: #0072ce;
  background-image: linear-gradient(180deg, #0072ce, #0033a0);
}

.nav-list {
  vertical-align: middle;
}

.nav-list-secondary {
  position: absolute;
  right: 30px;
  vertical-align: middle;
}

#header-logo-mpass {
  background: url("../assets/mpass_menu.png") no-repeat;
  content: "";
  width: 122px;
  height: 40px;
  display: table-cell;
  background-position: center;
}

ul li {
  display: table-cell;
  cursor: pointer;
  padding: 8px 10px;
  vertical-align: middle;
}

ul li a {
  padding: 10px 10px;
  text-align: justify;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-style: normal;
  text-decoration: none;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.nav-list li:hover {
  background-color: #fff;
  border-radius: 5px;
}

.nav-list li:hover a {
  color: #0072ce !important;
}

.nav-list-secondary li a {
  text-decoration: none;
  position: relative;
  padding-left: 10px;
}

.nav-list-secondary li a:hover {
  text-decoration: underline;
}

.material-icons {
  position: absolute;
  top: 5px;
  left: -16px;
}
</style>
