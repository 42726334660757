import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/operator",
    name: "Operator",
    component: () => import("../views/Operator.vue"),
  },
  {
    path: "/integrators",
    name: "Integrators",
    component: () => import("../views/Integrators.vue"),
  },
  {
    path: "/transportcodes",
    name: "Transportcodes",
    component: () => import("../views/Transportcodes.vue"),
  },
  {
    path: "/api",
    name: "API",
    component: () => import("../views/Api.vue"),
  },
  {
    path: "/keys",
    name: "Keys",
    component: () => import("../views/Keys.vue"),
  },
  {
    path: "/integrator",
    name: "Integrator",
    component: () => import("../views/Integrator.vue"),
  },
  {
    path: "/operators",
    name: "Operators",
    component: () => import("../views/Operators.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
